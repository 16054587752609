import { CourseType, CourseVariant } from "./Course"
import { PrismicDocument } from "@prismicio/types"
import moment from "moment"
import { asText } from "@prismicio/helpers"
import { slugifyCourse } from "./common"

export const isOngoingCourse = (d: PrismicDocument<any>): boolean => {
  return (
    moment(d.data.registration_open_to).isBetween(moment().add(-3, "months"), moment(), undefined, "[]") &&
    d.data.type === CourseType.online &&
    d.data.course_type === CourseVariant.course
  )
}

export const isCourseRegistrationOpen = (d: PrismicDocument<any>): boolean => {
  return (
    d.data.registration_open_from &&
    d.data.registration_open_to &&
    moment().isAfter(moment(d.data.registration_open_from)) &&
    moment().isBefore(moment(d.data.registration_open_to))
  )
}

export const isCourseRegistrationBefore = (d: PrismicDocument<any>): boolean =>
  d.data.registration_open_from && moment().isBefore(moment(d.data.registration_open_from))

export const isCourseRegistrationAfter = (d: PrismicDocument<any>): boolean =>
  d.data.registration_open_to && moment().isAfter(moment(d.data.registration_open_to))

export const isSampleCourse = (d: PrismicDocument<any>): boolean => d.data.course_type === "sampleCourse"

export const isTraineeCourse = (d: PrismicDocument) => asText(d.data?.excerpt)?.toLowerCase()?.includes("trainee program")

export const isWorkshopCourse = (d: PrismicDocument<any>): boolean => d.data.course_type === "workshop"

export const isSummerSchoolCourse = (d: PrismicDocument<any>): boolean => slugifyCourse(d).slugify_name.includes("letni-skola")
